// 组件
// 接口
import * as rewardProductCategoriesApi from '@/services/reward-product-categories.js'
import * as rewardProductsApi from '@/services/reward-products.js'
// 其他
import dataDictionary from '@/utils/data-dictionary.js'
import constant from '@/utils/constant.js'
import verifyRules from '@/utils/verify-rules/index.js'
import loading from '@/utils/loading.json'
export default {
  data () {
    return {
      dataDictionary,
      constant,
      verifyRules: verifyRules(this),
      rewardableTypeList: [],
      rewardableList: [],
      rewardProductCategoryList: [],
      mainForm: {
        rewardableSelect: {
          disabled: true,
          loading: false
        },
        data: {
          online: true,
          rewardable_type: null,
          rewardable_id: null,
          reward_product_category_id: null,
          point_price: null,
          rank: null
        }
      }
    }
  },
  mounted () {
    const _this = this
    const initRequest1 = rewardProductsApi.getRewardableTypeList().then(res => {
      _this.rewardableTypeList = res.data.rewardable_type_list
      return res
    }).catch(() => {
      return false
    })
    const initRequest2 = rewardProductCategoriesApi.getRewardProductCategoryList().then(res => {
      _this.rewardProductCategoryList = res.data.reward_product_category_list
      return res
    }).catch(() => {
      return false
    })
    _this.setGlobalLoading(true)
    Promise.all([initRequest1, initRequest2]).then(() => {
      _this.setGlobalLoading(false)
    }).catch(() => {
      _this.setGlobalLoading(false)
    })
  },
  methods: {
    setGlobalLoading (show) {
      const _this = this
      show ? _this.$store.state.app.loading = _this.$loading(loading) : _this.$store.state.app.loading.close()
    },
    handleMainFormRewardableTypeSelectChange (val) {
      const _this = this
      if (val) {
        _this.mainForm.rewardableSelect.loading = true
        _this.mainForm.rewardableSelect.disabled = false
        _this.mainForm.data.rewardable_id = null
        _this.rewardableList = []
        rewardProductsApi.getItemables({
          rewardable_type: val
        }).then(res => {
          _this.mainForm.rewardableSelect.loading = false
          _this.mainForm.rewardableSelect.disabled = false
          _this.rewardableList = res.data.itemables
        }).catch(() => {
          _this.mainForm.rewardableSelect.loading = false
          _this.mainForm.rewardableSelect.disabled = false
        })
      } else {
        _this.mainForm.rewardableSelect.loading = false
        _this.mainForm.rewardableSelect.disabled = true
        _this.mainForm.data.rewardable_id = null
        _this.rewardableList = []
      }
    },
    handleMainFormCancelClick () {
      const _this = this
      _this.$router.go(-1)
    },
    handleMainFormSubmitClick () {
      const _this = this
      _this.$refs.mainForm.validate((valid) => {
        if (valid) {
          let requestParams = {}
          Object.assign(requestParams, _this.mainForm.data)
          requestParams = {
            reward_product: {
              ...requestParams
            }
          }
          _this.setGlobalLoading(true)
          rewardProductsApi.postRewardProducts(requestParams).then(() => {
            _this.setGlobalLoading(false)
            _this.$message({
              type: 'success',
              message: _this.$t('message.operateSuccess')
            })
            setTimeout(() => {
              _this.$router.push({
                name: 'rewardsStoreRewardsProductList'
              })
            }, constant.ROUTER_CHANGE_INTERVAL)
          }).catch(() => _this.setGlobalLoading(false))
        } else {
          _this.$message({
            type: 'warning',
            message: _this.$t('text.incompleteInformation')
          })
        }
      })
    }
  }
}
