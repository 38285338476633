import request from '@/utils/request'

export const getSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/reward_products/setup'
  })
}

export const getRewardProducts = (params) => {
  return request({
    method: 'GET',
    url: '/cms/reward_products',
    params
  })
}

export const postRewardProducts = (data) => {
  return request({
    method: 'POST',
    url: '/cms/reward_products',
    data
  })
}

export const getRewardableTypeList = () => {
  return request({
    method: 'GET',
    url: '/cms/reward_products/rewardable_type_list'
  })
}

export const getItemables = (params) => {
  return request({
    method: 'GET',
    url: '/cms/reward_products/itemables',
    params
  })
}

export const getIdPreview = (id) => {
  return request({
    method: 'GET',
    url: `/cms/reward_products/${id}/preview`
  })
}

export const getId = (id) => {
  return request({
    method: 'GET',
    url: `/cms/reward_products/${id}`
  })
}

export const deleteId = (id) => {
  return request({
    method: 'DELETE',
    url: `/cms/reward_products/${id}`
  })
}

export const putId = (id, data) => {
  return request({
    method: 'PUT',
    url: `/cms/reward_products/${id}`,
    data
  })
}
