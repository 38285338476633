<template>
  <div>
    <el-card>
      <el-form ref="mainForm" :model="mainForm.data" :rules="verifyRules.rewardsStoreRewardsProductAdd.mainForm" label-width="160px">
        <!-- <el-row :gutter="12">
          <el-col :md="24" :lg="24">
            <el-form-item :label="$t('text.online')" prop="online">
              <el-switch v-model="mainForm.data.online"></el-switch>
            </el-form-item>
          </el-col>
        </el-row> -->
        <el-row :gutter="12">
          <el-col :md="24" :lg="8">
            <!-- 类型 -->
            <el-form-item :label="$t('form.rewardsStore.rewardsProductType')" prop="rewardable_type">
              <el-select
                v-model="mainForm.data.rewardable_type"
                class="width-fill"
                :placeholder="$t('placeholder.defaultSelect')"
                clearable
                filterable
                @change="handleMainFormRewardableTypeSelectChange"
              >
                <el-option v-for="(item, index) in rewardableTypeList" :key="index" :label="item.name" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="24" :lg="8">
            <!-- 商品 -->
            <el-form-item :label="$t('text.product')" prop="rewardable_id">
              <el-select
                v-model="mainForm.data.rewardable_id"
                class="width-fill"
                :placeholder="$t('placeholder.defaultSelect')"
                clearable
                filterable
                :disabled="mainForm.rewardableSelect.disabled"
                :loading="mainForm.rewardableSelect.loading"
              >
                <el-option v-for="(item, index) in rewardableList" :key="index" :label="item.name" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="24" :lg="8">
            <!-- 商品类别 -->
            <el-form-item :label="$t('form.product.shopCategories')" prop="reward_product_category_id">
              <el-select
                v-model="mainForm.data.reward_product_category_id"
                class="width-fill"
                :placeholder="$t('placeholder.defaultSelect')"
                clearable
                filterable
              >
                <el-option v-for="(item, index) in rewardProductCategoryList" :key="index" :label="item.name" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="12">
          <el-col :md="24" :lg="8">
            <!-- 销售积分 -->
            <el-form-item :label="$t('form.rewardsStore.sellingPoints')" prop="point_price">
              <el-input
                v-model="mainForm.data.point_price"
                :placeholder="$t('placeholder.defaultInput')"
                :maxlength="constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <el-row :gutter="12">
          <el-col :md="24" :lg="8">
            <el-form-item :label="$t('form.common.rank')" prop="rank">
              <el-input
                v-model="mainForm.data.rank"
                :placeholder="$t('placeholder.defaultInput')"
                :maxlength="constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row> -->
        <el-divider></el-divider>
        <el-row type="flex" justify="center">
          <el-button @click="handleMainFormCancelClick">{{ $t('button.cancel') }}</el-button>
          <el-button type="primary" @click="handleMainFormSubmitClick">{{ $t('button.confirm') }}</el-button>
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import Logic from './index.js'
export default Logic
</script>

<style lang="scss" scope>
@import './index.scss';
</style>
